/* You can add global styles to this file, and also import other style files */

@import "assets/primeng-themes/enaio/theme.scss";
@import "assets/css/primeng.css";
@import "primeicons/primeicons.css";
@import "./assets/fonts/fonts.scss";

:root body {
  overflow-y: hidden;
}

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-light.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}
:root .lpx-content {
  height: 100vh;
  padding: 0px;
  @media (max-width: 768px) {
    min-height: 100px;
    height: 100%;
  }

}
:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/logo-dark.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}
:root {
  --lpx-theme-light-bg: url('/assets/images/login/login-bg-img-light.svg');
  --lpx-theme-dim-bg: url('/assets/images/login/login-bg-img-dim.svg');
  --lpx-theme-dark-bg: url('/assets/images/login/login-bg-img-dark.svg');

  -webkit-font-smoothing: antialiased;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #E0EBEE;
  color: black;
  font-weight: normal;
}

#cookieConsent {
  display: none;
}
