$primaryColor: #3dace8 !default;
$primaryDarkColor: #0092e1 !default;
$primaryDarkerColor: #0080c6 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #e0f2fb !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
@import './_fonts';
@import '../../primeng-theme-base/_components';
@import '../extensions/_extensions';
